import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['wrapper', 'container', 'content', 'background', 'status', 'form']

  initialize() {
    this.url = this.data.get('url')
  }

  view(e) {
    if (e.target !== this.wrapperTarget &&
      !this.wrapperTarget.contains(e.target)) return
    $(this.statusTarget).data('status','open')
    if (this.open(e)) {
      this.getContent(this.url)
      this.wrapperTarget.insertAdjacentHTML('afterbegin', this.template())
    }
  }

  close(e) {
    e.preventDefault()

    if (this.open(e)) {
      if (this.hasContainerTarget) { this.containerTarget.remove() }
      $(this.statusTarget).data('status', 'close')
    }
  }

  submitForm(e) {
    e.preventDefault()
    Rails.fire(this.formTarget, 'submit')
    if (this.open(e)) {
      if (this.hasContainerTarget) { this.containerTarget.remove() }
      $(this.statusTarget).data('status', 'close')
    }
  }

  closeBackground(e) {
    if (e.target === this.backgroundTarget) { 
      this.close(e) 
      $(this.statusTarget).data('status', 'close')
    }
  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27) {
      this.close(e)
      $(this.statusTarget).data('status', 'close')
    }
  }

  open(e) {
    if ($(this.statusTarget).data('status') == 'open'){
      return true
    }else{
      return false
    }
  }

  getContent(url) {
    fetch(url).
      then(response => {
        if (response.ok) {
          return response.text()
        }
      })
      .then(html => {
        this.contentTarget.innerHTML = html
      })
  }

  template() {
    return `
      <div data-remote-target='container' class='fixed z-10 inset-0 overflow-y-auto'>
        <div class='modal-wrapper flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block md:block sm:p-0' data-remote-target='background' data-action='click->remote#closeBackground'>
          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div class='inline-block align-bottom bg-white border border-gray-300 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all md:w-4/12 md:p-6 md:my-4 sm:my-2 sm:align-middle sm:min-w-max sm:p-2'>
            <div data-remote-target='content'>
            </div>
          </div>
        </div>
      </div>
    `
  }
}
