import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["dropdown"]

    toggle(ev) {
        $(this.dropdownTarget).toggleClass("hidden");
        ev.preventDefault();
        return false;
    }

}
