import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dropdown", "input"]
  
  
  keyup(ev) {
    window.element = ev.srcElement
    if(ev.key == "ArrowDown") {
      if($("ul.search-items li.selected").length > 0) {
        var next_li = $("ul.search-items li.selected").next("li")
        $("ul.search-items li.selected").removeClass("selected bg-yellow-500 text-white")
        $(next_li).addClass("selected bg-yellow-500 text-white")
      } else {
        $("ul.search-items li:first").addClass("selected bg-yellow-500 text-white")
      }
    } else if (ev.key == "ArrowUp") {
      
      if($("ul.search-items li.selected").length > 0) {
        var prev_li = $("ul.search-items li.selected").prev("li")
        $("ul.search-items li.selected").removeClass("selected bg-yellow-500 text-white")
        $(prev_li).addClass("selected bg-yellow-500 text-white")
      } else {
        $("ul.search-items li:last").addClass("selected bg-yellow-500 text-white")
      }
      
    } else if (ev.key == "Enter") {
      
      if($("ul.search-items li.selected").length > 0) {
        if ($("ul.search-items li.selected").text() == "View All Search Results") {
          window.location.href = "/products/search_results?q=" + $(window.element).val()
        } else {
          window.location.href = $("ul.search-items li.selected a").attr("href")
        }
      } else {
        window.location.href = "/products/search_results?q=" + $(window.element).val()
      }
      
    } else {
      clearTimeout(window.nextSearch)
      window.nextSearch = setTimeout(function() {
        $.ajax({
          url: "/search", 
          data: { 
             "query": $(window.element).val(),
          }
       })
      }, 250)
    }
  }
  
  viewed(ev) {
    console.log(ev)
    element = "#search"
    window.location.href = "/products/search_results?q=" + $(element).val()
  }
}
