import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["AdjustmentType", "AdjustmentAmount", "FormattedAmount", "form"]
  
  switchChanged(event) {
    const target = event.target
    let adjustment_type = $(this.AdjustmentTypeTarget).val()
    let adjustment_amount = $(this.AdjustmentAmountTarget).val()
    if(adjustment_type){
      if (adjustment_type == 'Fixed'){
        $(this.FormattedAmountTarget).html(this.to_usd(adjustment_amount))
      } else if (adjustment_type == 'Percent'){
        let percent = Number(adjustment_amount / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })
        $(this.FormattedAmountTarget).html(percent)
      } else {
        $(this.FormattedAmountTarget).html("")
      }
    }
  }

  to_usd(amount){
    let dollarUS2 = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      useGrouping: true,
    })
    return dollarUS2.format(amount)
  }
}