// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";global.Rails = Rails;
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("jquery")

//import owlCarousel from "owl.carousel";
//import "owl.carousel/dist/assets/owl.carousel.css";

require("owl.carousel")
require("owl.carousel/dist/assets/owl.carousel")

require("photoswipe")

import  "../stylesheets/application";
import "controllers";

Rails.start()
Turbolinks.start()
ActiveStorage.start()