import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dropdown"]
  
  toggle(ev) {
    $(this.dropdownTarget).toggleClass("hidden");
    var link = $(this.dropdownTarget).parent().find('a.m_my_account');
    if($(link).data("status") == 'close'){
      $(link).data('status', 'open');
      $(link).find(".my_account").html("<svg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 -6 20 20' fill='currentColor'><path fill-rule='evenodd' d='M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z' clip-rule='evenodd' /></svg >");
    } else if ($(link).data('status') == 'open'){
      $(link).data('status', 'close');
      $(link).find(".my_account").html("<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"h-5 w-5\" viewBox=\"0 -6 20 20\" fill=\"currentColor\"><path fill-rule=\"evenodd\" d=\"M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z\" clip-rule=\"evenodd\" /></svg >");
    }
    ev.preventDefault();
    return false;
  }
  
}
