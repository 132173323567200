import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["closebutton"]

  toggle(ev) {
    $(this.closebuttonTarget).toggleClass("hidden");
    ev.preventDefault();
    return false;
  }

}