import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["quantity", "price", "shipping", "total", "vendor", "destroy"]

  initialize() {
    this.totalTarget.value = parseFloat(this.calculateTotal()).toFixed(2);
  }

  mpnChange() {
    this.totalTarget.value = parseFloat(this.calculateTotal()).toFixed(2);
  }

  quantityChange() {
    this.totalTarget.value = parseFloat(this.calculateTotal()).toFixed(2);
  }

  shippingChange() {
    this.totalTarget.value = parseFloat(this.calculateTotal()).toFixed(2);
  }
  
  destroyChange() {
    this.totalTarget.value = parseFloat(this.calculateTotal()).toFixed(2);
  }
  
  vendorChange() {
    $.ajax({
      url: "vendor_notes",
      data: {
        vendor: this.vendorTarget.value
      }
    })
  }

  parseValue(arg) {
    if (isNaN((arg.value))) {
      return 0
    }
    else {
      return parseFloat(arg.value)
    }
  }

  calculateTotal(){
    let total = this.parseValue(0);
    this.quantityTargets.forEach((quantity, index) => {
      if (!this.destroyTargets[index].checked && !isNaN(this.parseValue(this.priceTargets[index])) && !isNaN(this.parseValue(quantity))) {
        total += this.parseValue(this.priceTargets[index]) * this.parseValue(quantity);
      }
    })
    let shipping = parseFloat(this.shippingTarget.value)
    if (isNaN(shipping)) {
      shipping = 0 
    }
    total += shipping
    return total;
  }

}
