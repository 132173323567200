import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dropdown"]
  
  toggle(ev) {
    const nestingLevel = this.data.get("level");
    const hidden = $(this.dropdownTarget).hasClass("hidden");
    for (let i=nestingLevel;i<4;i++) {
      $(".navcategories-"+i).addClass("hidden");
    }
    if (hidden) {
      $(this.dropdownTarget).toggleClass("hidden");
    }
    ev.preventDefault();
    return false;
  }
  
}
