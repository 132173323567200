import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["ApplyB2B", "CompanyB2B", "PhoneB2B", "BusinessTypeIds"]
  
  switchChanged(event) {
    const target = event.target
    let applyb2b_company = $(this.CompanyB2BTarget)
    let applyb2b_phone = $(this.PhoneB2BTarget)
    let applyb2b_additional = $(this.ApplyB2BTarget)
    let applyb2b_business_type_ids = $(this.BusinessTypeIdsTarget)
    if(target.checked){
      $(applyb2b_additional[0]).removeClass("hidden")
      $(applyb2b_company[0]).attr("required", true)
      $(applyb2b_phone[0]).attr("required", true)
      $(applyb2b_business_type_ids[0]).attr("required", true)
    } else {
      $(applyb2b_additional[0]).addClass("hidden")
      $(applyb2b_company[0]).removeAttr("required")
      $(applyb2b_phone[0]).removeAttr("required")
      $(applyb2b_business_type_ids[0]).removeAttr("required")
    }
  }
}