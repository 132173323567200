import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["select", "productDescription", "packSize", "quantity", "received", "price", "subtotal", "destroy"]

    initialize() {
        this.subtotalTarget.value = parseFloat(this.calculate_subtotal()).toFixed(2);
    }

    mpnChange() {
      let parent = this
      $.ajax({
        url: "product_select.json?product=" + this.selectTarget.value,
        success: function(data) {
          let manufacturer = data["manufacturer"]
          let price = data["price"]
          let product_name = data["name"]
          let pack_size = data["pack_size"]
          let sold_as = data["sold_as"]
          
          parent.productDescriptionTarget.value = `${manufacturer} - ${product_name}`
          parent.priceTarget.value = price
          parent.packSizeTarget.value = `${pack_size}/${sold_as}`
          parent.subtotalTarget.value = parseFloat(parent.calculate_subtotal()).toFixed(2)
        }
      })
        // let manufacturer = this.selectTarget.options[this.selectTarget.selectedIndex].outerHTML.match(/manufacturer="(.*?)"/)[1];
        // let price = this.selectTarget.options[this.selectTarget.selectedIndex].outerHTML.match(/price="(.*?)"/)[1];
        // let product_name = this.selectTarget.options[this.selectTarget.selectedIndex].outerHTML.match(/name="(.*?)"/)[1];
        // let packSize = this.selectTarget.options[this.selectTarget.selectedIndex].outerHTML.match(/manufacturer_pack_size="(.*?)"/)[1];
        // let sold_as = this.selectTarget.options[this.selectTarget.selectedIndex].outerHTML.match(/sold_as="(.*?)"/)[1];
        // this.productDescriptionTarget.value = `${manufacturer} - ${product_name}`
        // this.priceTarget.value = price
        // this.packSizeTarget.value = `${packSize}/${sold_as}`
        // this.subtotalTarget.value = parseFloat(this.calculate_subtotal()).toFixed(2)
    }

    quantityChange() {
      this.subtotalTarget.value = parseFloat(this.calculate_subtotal()).toFixed(2);
    }
    
    destroyChange() {
      this.subtotalTarget.value = parseFloat(this.calculate_subtotal()).toFixed(2);
    }

    calculate_subtotal() {
      if(this.destroyTarget.checked) {
        return 0
      } else if (isNaN((this.quantityTarget.value))) {
        return 0
      }
      else {
        return (this.quantityTarget.value) * (this.priceTarget.value)
      }
    }


}
